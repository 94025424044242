import React, { Component } from 'react'

export default class Space extends Component {
    render() {
        return (
            <div>
                <section className="colorlib-about" data-section="space">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInRight">
                                <span className="heading-meta">Espace de travail</span>
                                <h2 className="colorlib-heading">Mes autres projets</h2>
                            </div>
                        </div>
                        <div className="row row-pt-md">
                            <div className="col-md-4 col-sm-6 text-center bloc-center animate-box" data-animate-effect="fadeInUp">
                                <div className="services" style={{ borderColor: 'purple' }}>
                                    <h3 className="uppercase"><a href="https://app.payet-portfolio.fr/" target="_blank" rel="noreferrer">Espace application</a></h3>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 text-center bloc-center animate-box" data-animate-effect="fadeInUp">
                                <div className="services" style={{ borderColor: 'darkred' }}>
                                    <h3 className="uppercase"><a href="https://blog.payet-portfolio.fr/" target="_blank" rel="noreferrer">Espace blog</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
