import React, { Component } from "react";

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div className="col-md-12">
                <div
                  className="row row-bottom-padded-sm animate-box"
                  data-animate-effect="fadeInRight"
                >
                  <div className="col-md-12">
                    <div className="about-desc">
                      <span className="heading-meta">À Propos</span>
                      <h2 className="colorlib-heading">Qui suis-je?</h2>
                      <p className="my-4">
                        Avec un parcours atypique, j'ai commencé ma carrière
                        professionnelle en tant qu'électricien dans le BTP. J'ai
                        par la suite repris mes études afin de travailler dans
                        le secteur du commerce B2C et dans les activités de
                        services à la personne.
                      </p>
                      <p className="my-4">
                        Mais, étant attiré par les sciences et les nouvelles
                        technologies depuis petit, j'ai entamé une reconversion
                        profesionnelle afin de combiner passion et emploi. En
                        obtenant mon diplôme de « Développeur Web et Web Mobile
                        », j'ai pu trouver ma voie dans ce vaste monde qu'est la
                        programmation informatique. J'aime pouvoir développer
                        différents projets car cela me motive à me surpasser
                        dans un domaine en perpétuelle évolution.
                        <br />
                        En parallèle, je suis également un grand passionné de
                        sciences (astronomie, espace) et de musique. Lorsque
                        l'occasion se présente, je n'hésite pas à déployer ma
                        régie afin de mixer en tant que DJ semi-amateur.
                      </p>
                      {/* <p className="my-4">
                        À l'heure actuelle, j'aspire à évoluer en tant que
                        développeur JavaScript FullStack (comprenant aussi le
                        secteur du DevOps) en participant à de divers projets,
                        qu'ils soient open-source ou non. Je cherche aussi à
                        affuter mes connaissances théoriques et pratiques en
                        intégrant par la suite un cursus de "Concepteur et
                        développeur d'applications".
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="colorlib-about">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInLeft"
              >
                <span className="heading-meta">Ce que je fais?</span>
                <h2 className="colorlib-heading">
                  Voici quelques-unes de mes compétences
                </h2>
              </div>
            </div>
            <div className="row row-pt-md">
              <div className="col-md-4 col-sm-6 text-center bloc-center animate-box">
                <div className="services color-1">
                  <span className="icon">
                    <i className="icon-bulb" />
                  </span>
                  <div className="desc">
                    <h3>Développement web</h3>
                    <p className="my-4">
                      Pour créer des projets web, j'utilise principalement les
                      langages suivants :{" "}
                      <span className="text-bold">HTML</span>,{" "}
                      <span className="text-bold">CSS</span> et{" "}
                      <span className="text-bold">JS</span>. En fonction du
                      projet, je peux utiliser des CMS tel que :{" "}
                      <span className="text-bold">WordPress</span> et{" "}
                      <span className="text-bold">Strapi</span>, faire appel à
                      des librairies comme{" "}
                      <span className="text-bold">React.js</span> ou{" "}
                      <span className="text-bold">Vue.js</span> ou encore me
                      servir d'un ou plusieurs Frameworks :{" "}
                      <span className="text-bold">Next.js</span>,{" "}
                      <span className="text-bold">Nuxt.js</span> et{" "}
                      <span className="text-bold">Tailwind</span>.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center bloc-center animate-box">
                <div className="services color-5">
                  <span className="icon">
                    <i className="icon-layers2" />
                  </span>
                  <div className="desc">
                    <h3>Structure web</h3>
                    <p className="my-4">
                      Dans un soucis de maintenabilité et d'évolutivité,
                      j'accorde une très grande importance à disposer d'un code
                      propre et cohérent. Dans chacun de mes projets,
                      l'arborescence des dossiers et des fichiers doit être
                      claire et concise. Cela me permet entre autre d'optimiser
                      mon temps et ma productivité.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center bloc-center animate-box">
                <div className="services color-2">
                  <span className="icon">
                    <i className="icon-data" />
                  </span>
                  <div className="desc">
                    <h3>Veille technologique</h3>
                    <p className="my-4">
                      Afin de ne pas être dépassé par la vitesse à laquelle les
                      technologies évoluent, j'effectue régulièrement une veille
                      technologique en visitant des blogs, des sites tel que{" "}
                      <a
                        href="https://medium.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="underline">Medium</span>
                      </a>{" "}
                      et en participant à des disscussions de groupes de
                      développeurs confirmés. En parallèle, je suis des cours
                      certifiant sur la plateforme{" "}
                      <a
                        href="https://www.dyma.fr/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span className="underline">Dyma</span>.
                      </a>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 text-center bloc-center animate-box">
                <div className="services color-3">
                  <span className="icon">
                    <i className="icon-phone3" />
                  </span>
                  <div className="desc">
                    <h3>Marketing Digital</h3>
                    <p className="my-4">
                      En élaborant des statégies sur mesure, le marketing
                      digital me permet de cibler de nouveaux clients en se
                      différenciant de la concurrence. Grâce à plusieurs outils,
                      il sera possible d'augmenter le taux de visibilité et de
                      conversion en utilisant notamment le référencement (
                      <span className="text-bold">SEO</span> et{" "}
                      <span className="text-bold">SEA</span>), le{" "}
                      <span className="text-bold">SMO</span> (
                      <span className="text-bold">
                        Social Media Optimization
                      </span>
                      ) etc..
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
