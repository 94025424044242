import React, { useState } from "react";
import { Button, Modal, Carousel } from "react-bootstrap";

export default function Projects() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title>HelloDev V1</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel activeIndex={index} onSelect={handleSelect} fade>
            <Carousel.Item>
              <Modal.Header style={{ justifyContent: "center" }}>
                <Modal.Title>Version Desktop</Modal.Title>
              </Modal.Header>
              <img
                className="d-block w-100"
                src="images/hd-1.jpg"
                alt="Première slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <Modal.Header style={{ justifyContent: "center" }}>
                <Modal.Title>Version Mobile</Modal.Title>
              </Modal.Header>
              <img
                className="d-block w-100"
                src="images/hd-2.jpg"
                alt="Deuxième slide"
              />
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      <section id="projects" className="colorlib-work" data-section="projects">
        <div className="colorlib-narrow-content">
          <div className="row">
            <div
              className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
              data-animate-effect="fadeInRight"
            >
              <span className="heading-meta">Mon travail</span>
              <h2 className="colorlib-heading animate-box">Projets récents</h2>
            </div>
          </div>
          <div className="row">
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
            >
              <div
                className="project"
                onClick={handleShow}
                style={{
                  backgroundImage: "url(images/hello-dev.jpg)",
                  cursor: "pointer",
                }}
              >
                <div className="desc">
                  <div className="con">
                    <h3 style={{ textTransform: "uppercase", color: "white" }}>
                      Projet N°1
                    </h3>
                    <span
                      style={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      Site Vitrine WordPress
                    </span>
                    {/* <p className="icon">
												<span><a href="#"><i className="icon-share3" /></a></span>
												<span><a href="#"><i className="icon-eye" /> 100</a></span>
												<span><a href="#"><i className="icon-heart" /> 49</a></span>
											</p> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
            >
              <div
                className="project"
                style={{ backgroundImage: "url(images/portfolio.jpg)" }}
              >
                <a
                  href="https://payet-portfolio.fr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="desc">
                    <div className="con">
                      <h3
                        style={{ textTransform: "uppercase", color: "white" }}
                      >
                        Projet N°2
                      </h3>
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        Portfolio React.js
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
            >
              <div
                className="project"
                style={{ backgroundImage: "url(images/sam2g.jpg)" }}
              >
                <a
                  href="https://sam2g.fr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="desc">
                    <div className="con">
                      <h3
                        style={{ textTransform: "uppercase", color: "white" }}
                      >
                        Projet N°3
                      </h3>
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        Site Client WordPress
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
            >
              <div
                className="project"
                style={{ backgroundImage: "url(images/logoPortfolio.png)" }}
              >
                <a
                  href="https://hello-dev.fr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="desc">
                    <div className="con">
                      <h3
                        style={{ textTransform: "uppercase", color: "white" }}
                      >
                        Projet N°4
                      </h3>
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        Site Vitrine V2 Next.js
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
            >
              <div
                className="project"
                style={{ backgroundImage: "url(images/th.jpg)" }}
              >
                <a
                  href="https://terres-histoire.sam2g.fr/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="desc">
                    <div className="con">
                      <h3
                        style={{ textTransform: "uppercase", color: "white" }}
                      >
                        Projet N°5
                        <br />
                        <span className="mt-4 uppercase text-white">
                          Prochainement
                        </span>
                      </h3>
                      <span
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                        }}
                      >
                        Futur Site Client WordPress
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
              style={{ cursor: "default" }}
            >
              <div
                className="project"
                style={{
                  backgroundImage: "url(images/site-musique.jpg)",
                }}
              >
                <div className="desc">
                  <div className="con">
                    <h3 style={{ textTransform: "uppercase", color: "white" }}>
                      Projet N°6
                      <br />
                      <span className="mt-4 uppercase text-white">
                        Prochainement
                      </span>
                    </h3>
                    <span
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Futur site de musique <br />
                      Next.js - Strapi
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
              style={{ cursor: "default" }}
            >
              <div
                className="project"
                style={{
                  backgroundImage: "url(images/application-mobile.png)",
                }}
              >
                <div className="desc">
                  <div className="con">
                    <h3 style={{ textTransform: "uppercase", color: "white" }}>
                      Projet N°7
                      <br />
                      <span className="mt-4 uppercase text-white">
                        Prochainement
                      </span>
                    </h3>
                    <span
                      style={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      Future application mobile
                      <br />
                      React Native - Firebase
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
              style={{ cursor: "default" }}
            >
              <div
                className="project"
                style={{
                  backgroundImage: "url(images/site-app.jpg)",
                }}
              >
                <div className="desc">
                  <div className="con">
                    <h3 style={{ textTransform: "uppercase", color: "white" }}>
                      Projet N°8
                      <br />
                      <span className="mt-4 uppercase text-white">
                        Prochainement
                      </span>
                    </h3>
                    <span
                      style={{
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      }}
                    >
                      Futur site lié à l'application mobile <br />
                      Vue.js - Strapi
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 animate-box"
              data-animate-effect="fadeInBottom"
              style={{ cursor: "default" }}
            >
              <div
                className="project"
                style={{
                  backgroundImage: "url(images/under-construction.jpg)",
                }}
              >
                <div className="desc">
                  <div className="con">
                    <h3 style={{ textTransform: "uppercase", color: "white" }}>
                      À venir
                    </h3>
                    <span
                      style={{ textTransform: "uppercase", fontWeight: "bold" }}
                    >
                      En Construction
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
							<div className="col-md-12 animate-box">
								<p><a href="#" className="btn btn-primary btn-lg btn-load-more">Load more <i className="icon-reload" /></a></p>
							</div>
						</div> */}
        </div>
      </section>
    </div>
  );
}
