import React, { Component } from "react";

export default class Timeline extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-experience" data-section="timeline">
          <div className="colorlib-narrow-content">
            <div className="row">
              <div
                className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box"
                data-animate-effect="fadeInRight"
              >
                <span className="heading-meta">Points forts</span>
                <h2 className="colorlib-heading animate-box">Chronologie</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="timeline-centered">
                  {/* <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Recherche d'un poste <span>2021 - À aujourd'hui</span>
                        </h2>
                        <p className="my-4">
                          Actuellement, je suis à la recherche d'un contrat en
                          CDD ou CDI auprès d'une entreprise qui me permettrait
                          de me perfectionner dans le domaine du JavaScript. En
                          tant que développeur web Front-End, je souhaite mettre
                          en pratique l'ensemble de mes compétences dans un
                          milieu professionnel.
                        </p>
                      </div>
                    </div>
                  </article> */}
                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-3">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Coach/Formateur développeur web FullStack JS{" "}
                          <span>2022 - À aujourd'hui</span>
                        </h2>
                        <p className="my-4">
                          En parallèle de mon activité en free-lance, je
                          travaille chez l'
                          <a
                            href="https://www.accesscodeschool.fr/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="underline">
                              Access Code School
                            </span>
                          </a>{" "}
                          en tant que coach / formateur.
                          <br />
                          L'ACS est une école dédiée à l'apprentissage des
                          technologies du monde numérique et labelisée Grande
                          École du Numérique (GEN).
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-7">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Développeur web en free-lance{" "}
                          <span>2020 - À aujourd'hui</span>
                        </h2>
                        <p className="my-4">
                          Fondateur de{" "}
                          <a
                            href="https://hello-dev.fr"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <span style={{ textDecoration: "underline" }}>
                              HelloDev
                            </span>
                          </a>
                          , j'opère en tant que freelance dans la création et la
                          refonte d'applications web. Pour en savoir plus,
                          n'hésitez pas à visiter le site de mon agence ou à me
                          contacter par téléphone ou par e-mail (coordonnées
                          visibles sur le menu de gauche).
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInRight"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-2">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Veille technologique <span>2020 - À aujourd'hui</span>
                        </h2>
                        <p className="my-4">
                          Afin de suivre les dernières tendances et les
                          nouvelles innovations, je suis plusieurs cours
                          certifiants sur le site francophone populaire du nom
                          de{" "}
                          <a
                            href="https://dyma.fr/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {" "}
                            Dyma
                          </a>
                          . Cela me permet de rester à l'écoute du marché et
                          d'accroître conjointement mes connaissances ainsi que
                          mes compétences. En parallèle, je me documente
                          régulièrement sur les Frameworks que j'utilise le plus
                          souvent.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className="timeline-entry animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-6">
                        <i className="icon-pen2" />
                      </div>
                      <div className="timeline-label">
                        <h2>
                          Formation de développeur web <span>2019 - 2020</span>
                        </h2>
                        <p className="my-4">
                          Anciennement vendeur en service à la personne, j'ai
                          décidé de me ré-orienter dans le domaine de la
                          programmation informatique. J'ai pu suivre une
                          formation de "Développeur web & web mobile" au sein de
                          l'Afpa de Paris. Grâce à cette formation, j'ai appris
                          à me familiariser avec les différents langages de
                          base.
                        </p>
                      </div>
                    </div>
                  </article>
                  <article
                    className="timeline-entry begin animate-box"
                    data-animate-effect="fadeInLeft"
                  >
                    <div className="timeline-entry-inner">
                      <div className="timeline-icon color-none"></div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
