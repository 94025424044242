import React, { Component } from 'react'

export default class Contact extends Component {
    render() {
        return (
            <div className="container-contact">
                <section className="colorlib-contact" data-section="contact">
                    <div className="colorlib-narrow-content">
                        <div className="row">
                            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInRight">
                                <span className="heading-meta">À votre écoute</span>
                                <h2 className="colorlib-heading">Contact</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInTop">
                                    <div className="colorlib-icon">
                                        <i className="icon-mail"></i>
                                    </div>
                                    <div className="colorlib-text">
                                        <p>
                                            <a href="mailto:contact@payet-portfolio.fr">contact@payet-portfolio.fr</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInTop">
                                    <div className="colorlib-icon">
                                        <i className="icon-phone"></i>
                                    </div>
                                    <div className="colorlib-text">
                                        <p>
                                            <a href="tel:+33761042695">+33 (0)761042695</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-16">
                                <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInTop">
                                    <div className="colorlib-icon">
                                        <i className="icon-social-linkedin"></i>
                                    </div>
                                    <div className="colorlib-text">
                                        <p>
                                            <a href="https://linkedin.com/in/enrick-payet/">https://linkedin.com/in/enrick-payet/</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-16">
                                <div className="colorlib-feature colorlib-feature-sm animate-box" data-animate-effect="fadeInTop">
                                    <div className="colorlib-icon">
                                        <i className="icon-link2"></i>
                                    </div>
                                    <div className="colorlib-text">
                                        <p>
                                            <a href="https://malt.fr/profile/enrickpayet">https://malt.fr/profile/enrickpayet</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
