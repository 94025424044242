import React, { Component } from "react";
import Typist from "react-typist";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default class Introduction extends Component {
  render() {
    return (
      <div>
        <section
          id="colorlib-hero"
          className="js-fullheight"
          data-section="home"
        >
          <div className="flexslider js-fullheight">
            <ul className="slides text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl">
              <li style={{ backgroundImage: "url(images/unsplash.png)" }}>
                <div className="overlay" />
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-flex flex-row justify-content-center align-items-center js-fullheight slider-text">
                      <div className="d-flex flex-row justify-content-center align-items-center slider-text-inner js-fullheight">
                        <div className="desc mb-40">
                          <div
                            id="test"
                            className="d-flex justify-content-center text-center"
                          >
                            <Typist startDelay={1000}>
                              <span style={{ color: "#2c98f0" }}>{"<p>"}</span>
                              <Typist.Backspace count={0} delay={200} />
                              <span>Bonjour !</span>
                              <Typist.Backspace count={0} delay={200} />
                              <span> Je m'appelle Enrick</span>
                              <Typist.Backspace count={20} delay={200} />
                              <span>
                                {" "}
                                Je suis <br />
                                développeur web FullStack JS
                                <span style={{ color: "#2c98f0" }}>
                                  {"</p>"}
                                </span>
                              </span>
                            </Typist>
                          </div>
                          <div className="d-flex justify-content-center my-8">
                            <Typist className="myTipist">
                              <Typist.Delay ms={6500} />
                              <a
                                className="btn btn-primary btn-learn hover-link"
                                href={"docs/cv-payet.pdf"}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Mon CV
                              </a>
                              <AnchorLink href="#projects">
                                <a className="btn btn-primary btn-learn hover-link">
                                  Mes projets
                                </a>
                              </AnchorLink>
                            </Typist>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}
