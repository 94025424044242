import React, { Component } from "react";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          <nav
            href="#navbar"
            className="js-colorlib-nav-toggle colorlib-nav-toggle"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar"
          >
            <i />
          </nav>
          <aside
            id="colorlib-aside"
            className="border"
            style={{ height: "100%" }}
          >
            <div className="text-center">
              <a href="https://payet-portfolio.fr/">
                <div
                  className="author-img"
                  style={{ backgroundImage: "url(images/profil-picture.jpg)" }}
                />
              </a>
              <h1 id="colorlib-logo">
                Enrick Payet
                <br />
                <p className="job-title" style={{ marginTop: "1rem" }}>
                  Développeur web FullStack JS
                  <br />(
                  <span style={{ fontStyle: "italic" }}> React / Node </span>)
                </p>
              </h1>
              <span className="email">
                <i className="icon-mail"></i>
                <a
                  className="link-blue"
                  href="mailto:contact@payet-portfolio.fr"
                >
                  {" "}
                  contact@payet-portfolio.fr
                </a>
              </span>
              <span className="email" style={{ marginBottom: "0.5rem" }}>
                <i className="icon-phone"></i>
                <a className="link-blue" href="tel:+33761042695">
                  {" "}
                  +33 (0)761042695
                </a>
              </span>
            </div>
            <nav
              id="colorlib-main-menu"
              style={{ justifyContent: "center" }}
              role="navigation"
              className="navbar"
            >
              <div id="navbar" className="collapse">
                <ul>
                  <li className="active">
                    <a href="#accueil" data-nav-section="home">
                      Introduction
                    </a>
                  </li>
                  <li>
                    <a href="#a-propos" data-nav-section="about">
                      À Propos
                    </a>
                  </li>
                  <li>
                    <a href="#parcours" data-nav-section="timeline">
                      Parcours
                    </a>
                  </li>
                  <li>
                    <a href="#projets" data-nav-section="projects">
                      Projets web
                    </a>
                  </li>
                  <li>
                    <a href="#contact" data-nav-section="space">
                      Espace web
                    </a>
                  </li>
                  <li>
                    <a href="#contact" data-nav-section="contact">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div id="app-portfolio">
                <a className="app-portfolio-link" href="https://app.payet-portfolio.fr" style={{ display: 'flex', justifyContent: 'center', textTransform: 'uppercase', color: '#000000bf', fontSize: '12px', marginTop: '-0.8em' }}>Mes Applications</a>
              </div> */}
            </nav>
            {/* <nav id="colorlib-main-menu">
              <ul>
                <li><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="icon-facebook2" /></a></li>
                <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="icon-twitter2" /></a></li>
                <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="icon-instagram" /></a></li>
                <li><a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><i className="icon-linkedin2" /></a></li>
                <li><a href="https://github.com/" target="_blank" rel="noopener noreferrer"><i className="icon-github"></i></a></li>
                <li><a href="https://medium.com/" target="_blank" rel="noopener noreferrer"><i className="icon-blogger2"></i></a></li>
              </ul>
            </nav> */}
            <div className="colorlib-footer">
              <ul>
                <li>
                  <a
                    href={"docs/cv-payet.pdf"}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="icon-cloud-download2"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/in/enrick-payet/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-linkedin2" />
                  </a>
                </li>
                {/*<li><a href="https://hello-dev.fr/" target="_blank" rel="noopener noreferrer"><i className="icon-link2"></i></a></li> */}
                <li>
                  <a
                    href="https://github.com/lazy974/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="icon-github"></i>
                  </a>
                </li>
              </ul>
            </div>
            <p className="footer-desc text-center text-italic">
              © 2020 - 2021.{" "}
              <a
                className="link-blue"
                href="https://linkedin.com/in/enrick-payet"
                target="_blank"
                rel="noopener noreferrer"
              >
                @Enrick Payet
              </a>
            </p>
          </aside>
        </div>
      </div>
    );
  }
}
